.App {
  text-align: center;
  background-image: url("./imagenes/imagenFondo.jpg");
  background-size: contain;   
}


.seccion1{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(5, 106, 126);
  padding-top: 1%;
  padding-bottom: 1%;
  color: white;
}


.text-principal{
  display: flex;
  align-items: center;
  justify-content: center;
}

.seccion2{
  background-color: white;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8%;
  margin-bottom: 8%;
}

.seccion3{
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.box-mision{
  background-color: rgb(0, 0, 0);
  color: white;
  margin-right: 5%;
  padding: 1%;
  border-radius: 10px 100px / 120px;

}

.box-vision{
  background-color: rgb(0, 0, 0);
  color: white;
  margin-left: 5%;
  padding: 2% 1% 2% 1%;
  border-radius: 10px 100px / 120px;
  
}
h2{
  font-size: 200%;
}

p{
  font-size: 150%;
}

.text{
  font-size: 100%;
}

.seccion4{
  padding-top: 10%;
  padding-bottom: 5%;
}
.filosofia{
  background-color: rgb(5, 106, 126);
  border-radius: 30px;
  padding: 1%;
  display: flex;
  justify-content: center;
  align-items: center;

}

.filosofia p{
  font-size: 120%;
  color: rgb(255, 255, 255);
}

h3{
  color: rgb(255, 255, 255);
  font-size: 200%;
}

.footer{
  background-color: rgb(5, 106, 126);
  padding-top: 2%;
  padding-bottom: 2%;
  color: white;
}

.box-container{
  display: flex;
  align-items: center;
  justify-content: space-around;

}

@media only screen and (max-width : 900px) {
 


.rowseccion2{
  display: flow ;
  padding:5%;
}

.seccion3{
  display: flow;
  padding: 2%;

}

.box-mision{

  padding: 1%;
  margin-bottom: 10%;

}

.box-vision{

  padding: 2%;
  
  
}
h2{

}

p{

}

.text{

}

.seccion4{

}
.filosofia{
  display: flow;
  padding: 10%;

}

.filosofia p{

}

h3{

}

.footer{

}

.box-container{

}

}


